import { useTranslation } from 'next-i18next'
import Head from 'next/head'
import dynamic from 'next/dynamic'

import { GetServerSidePropsContext } from 'next'

import { FormProvider } from 'react-hook-form'

import { useMarket } from '@/utils/multi-markets/context'
import Meta from '@/components/Meta'
import HeroHome from '@/components/home/HeroHome/HeroHome'
import HaveParking from '@/components/sections/HaveParking'
import ParkingSection from '@/components/home/Parking'
import ContactSection from '@/components/sections/ContactSection'
import Partners from '@/components/sections/Partners'
import { loadTranslationsWithMarket } from '@/utils/loadTranslations'
import Discover from '@/components/home/Discover/Discover'
import DefaultLayout from '@/components/layouts/Default'
import { useSearchNearParking } from '@/components/home/SearchNearParking/hooks/useSearchNearParking'

const FaqSection = dynamic(() => import('@/components/sections/Faq'))

export const getServerSideProps = async (
  context: GetServerSidePropsContext
) => {
  return loadTranslationsWithMarket(context, {
    cache: 'stale-while-revalidate',
    namespaces: ['home', 'common', 'opinions', 'parkings']
  })
}

function Home() {
  const { t } = useTranslation('home')
  const { host } = useMarket()

  const { form, onSubmit } = useSearchNearParking({})

  return (
    <>
      <DefaultLayout fullPage>
        <Head>
          <script
            type="application/ld+json"
            dangerouslySetInnerHTML={{
              __html: JSON.stringify({
                '@context': 'http://schema.org',
                '@type': 'Corporation',
                'name': 'NaviParking',
                'url': host,
                'logo': host + '/images/logo.png'
              })
            }}
          />
          <script
            type="application/ld+json"
            dangerouslySetInnerHTML={{
              __html: JSON.stringify({
                '@context': 'https://schema.org',
                '@type': 'LocalBusiness',
                'name': 'NaviParking',
                'image': host + '/images/logo.png',
                '@id': '',
                'url': host,
                'telephone': '+48 519 879 211',
                'address': {
                  '@type': 'PostalAddress',
                  'streetAddress': 'Chmielna 73',
                  'addressLocality': 'Warszawa',
                  'postalCode': '00-801',
                  'addressCountry': 'PL'
                }
              })
            }}
          />
        </Head>
        <Meta
          title={t('meta.title')}
          description={t('meta.description')}
          withoutPostfix
        />
        <FormProvider {...form}>
          <form onSubmit={form.handleSubmit(data => onSubmit(data, true))}>
            <HeroHome control={form.control} />
          </form>
        </FormProvider>
        <Discover />

        <ParkingSection />
        <FaqSection dataTestId={'home-faq'} forHomePage />
        <HaveParking />
        <ContactSection />
        <Partners />
      </DefaultLayout>
      <div id="modal-search-mobile" />
    </>
  )
}

export default Home
